<template>
  <div>
    <b-container class="mt-4">
      <b-card header-class="header">
        <template #header>
          <label v-text="'Mesaj Bilgileri'" class="mr-2 text-white"></label>
          <b-button variant="dark" class="mr-2" to="/messages">
            Mesajlar
          </b-button>
        </template>

        <b-container class="mt-4">
          <b-form>
            <b-row>
              <b-col>
                <div class="mt-4 text-danger font-weight-bold">
                  Tarih Saat :
                </div>
                <div class="mt-4 text-danger font-weight-bold">Kimden :</div>
                <div class="mt-4 text-danger font-weight-bold">Kime :</div>
                <div class="mt-4 text-danger font-weight-bold">Konu :</div>
                <div class="mt-4 text-danger font-weight-bold">Mesaj :</div>
              </b-col>
              <b-col>
                <div class="mt-4 font-weight-bold">
                  {{
                    form.createdAt
                      ? form.createdAt
                          .split("T")[0]
                          .split("-")
                          .reverse()
                          .join(".") +
                        " " +
                        form.createdAt
                          .split("T")[1]
                          .split(".")[0]
                          .split(":")
                          .join(".")
                      : ""
                  }}
                </div>
                <div class="mt-4 font-weight-bold">
                  {{
                    (form.sender ? form.sender.name : "Kano") +
                    " " +
                    (form.sender ? form.sender.surname : "Federasyonu")
                  }}
                </div>
                <div class="mt-4 font-weight-bold">
                  {{
                    (form.receiver ? form.receiver.name : "Kano") +
                    " " +
                    (form.receiver ? form.receiver.surname : "Federasyonu")
                  }}
                </div>
                <div class="mt-4 font-weight-bold">{{ form.title }}</div>
                <div class="mt-4 font-weight-bold">
                  {{ form.message }}
                </div>
              </b-col>
            </b-row>
          </b-form>
        </b-container>
      </b-card>
    </b-container>
  </div>
</template>


<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["getSingleMessage"]),
    form: {
      get() {
        return this.getSingleMessage;
      },
    },
  },
  mounted() {
    if (this.$route.params) {
      this.$store.dispatch("initSingleMessage", {
        messageId: this.$route.params.id,
        userId: this.$store.getters.getUser.id,
      });
      if (
        this.getSingleMessage.hasRead == 0 &&
        this.getSingleMessage.to == this.getUser.id
      ) {
        this.$store.dispatch("seeMessage", {
          ids: this.getSingleMessage.id,
          id: this.getUser.id,
        });
      }
    }
  },
};
</script>


<style scoped>
.header {
  background: rgb(108, 117, 125);
  background: linear-gradient(
    180deg,
    rgb(108, 117, 125) 10%,
    rgba(53, 57, 61, 1) 85%
  );
}
</style>